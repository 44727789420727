import React from 'react';
import { useFormik } from 'formik';
import { Tooltip, TooltipProvider } from 'react-tooltip';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const Form = ({ initialValues, fields, onSubmit, onCancel, validationSchema }) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true, // Permet de réinitialiser automatiquement lorsque initialValues change
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <TooltipProvider>
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        {fields.map((field, index) => (
          <div key={index} className="flex flex-col space-y-2 relative">
            <label htmlFor={field.name} className="text-sm font-medium text-gray-700">
              {field.label}
              {field.description && (
                <span
                  className="ml-2 text-gray-500 cursor-pointer"
                  data-tooltip-id={`tooltip-${field.name}`}
                >
                  <LightbulbOutlinedIcon sx={{ color: 'gray' }} />
                  <Tooltip id={`tooltip-${field.name}`} place="top" effect="solid">
                    {field.description}
                  </Tooltip>
                </span>
              )}
            </label>

            {field.type === 'array' ? (
              <>
                {(formik.values[field.name] || []).map((value, idx) => (
                  <div key={idx} className="flex space-x-2 items-center">
                    <input
                      type="number"
                      name={`${field.name}[${idx}]`}
                      value={value}
                      onChange={formik.handleChange}
                      className={`p-2 border ${formik.errors[field.name] ? 'border-red-500' : 'border-gray-300'
                        } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    />
                    <button
                      type="button"
                      onClick={() => formik.setFieldValue(field.name, formik.values[field.name].filter((_, i) => i !== idx))}
                      className="text-red-600 hover:text-red-800"
                    >
                      &times;
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => formik.setFieldValue(field.name, [...formik.values[field.name], ''])}
                  className="w-fit text-gray-600 bg-gray-200 hover:bg-gray-300 border rounded-md px-4 py-2"
                >
                  Ajouter un nombre
                </button>
              </>
            ) : field.options ? (
              <select
                id={field.name}
                name={field.name}
                value={formik.values[field.name] || ''}
                onChange={formik.handleChange}
                className={`p-2 border ${formik.errors[field.name] ? 'border-red-500' : 'border-gray-300'
                  } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
              >
                <option value=""> </option>
                {field.options.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={field.type || 'text'}
                id={field.name}
                name={field.name}
                value={formik.values[field.name] || ''}
                onChange={formik.handleChange}
                className={`p-2 border ${formik.errors[field.name] ? 'border-red-500' : 'border-gray-300'
                  } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
            )}

            {formik.errors[field.name] && (
              <span className="text-red-500 text-sm absolute top-full left-1/2 transform -translate-x-1/2 flex items-center">
                {formik.errors[field.name]}
                <ErrorOutlineOutlinedIcon className="h-4 w-4 mr-1" />
              </span>
            )}
          </div>
        ))}
        <div className="flex space-x-4">
          <button
            type="submit"
            className="px-4 py-2 bg-green-600 text-white font-semibold rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Valider
          </button>
          {onCancel && (
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 bg-black text-white font-semibold rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Annuler
            </button>
          )}
        </div>
      </form>
    </TooltipProvider>
  );
};

export default Form;
