import React, { useState, useEffect } from 'react';
import useFetchData from '../hooks/useFetchData';
import Table from '../components/Table';
import Form from '../components/Form';
import supabase from '../hooks/supabaseClient';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ConfirmationModal from '../components/ConfirmationModal';
import useFetchSelectOptions from '../hooks/useFetchSelectOptions';
import { getFormFields } from '../components/utils/MaillageFormFields';
import { maillageValidationSchema } from '../components/utils/ValidationShema';

const MaillagePage = () => {
    // Récupération des données depuis Supabase
    const { data, error, loading, fetchData } = useFetchData('links_maillage', 'id');
    const { categoryParents, rulesManagement, rootContext } = useFetchSelectOptions();

    // États pour la modification et suppression
    const [isEditing, setIsEditing] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    useEffect(() => {
        fetchData(); // Recharger les données au montage du composant
    }, [fetchData]);

    //  Modifier un lien
    const handleEdit = (item) => {
        console.log(" Édition de l'élément :", item); // Debugging
        setSelectedItem(item);
        setIsEditing(true);
    };

    //  Préparer la suppression
    const handleDeleteClick = (id) => {
        console.log(" Suppression demandée pour ID:", id); // Debugging
        if (!id || typeof id !== "number") {
            console.error(" Erreur : ID invalide pour la suppression", id);
            return;
        }
        setItemToDelete(id);
        setModalOpen(true);
    };

    //  Annuler la suppression
    const handleCloseModal = () => {
        setModalOpen(false);
        setItemToDelete(null);
    };

    //  Supprimer un lien
    const handleConfirmDelete = async () => {
        setModalOpen(false);

        if (!itemToDelete || typeof itemToDelete !== "number") {
            console.error(" Erreur : Aucun élément valide à supprimer !");
            return;
        }

        console.log(" Suppression en cours pour l'ID :", itemToDelete, " | Type :", typeof itemToDelete);

        const { error } = await supabase
            .from('links_maillage')
            .delete()
            .eq('id', itemToDelete);

        if (error) {
            console.error(' Erreur lors de la suppression:', error.message);
        } else {
            console.log(" Suppression réussie !");
            fetchData(); // Recharger les données après suppression
        }

        setItemToDelete(null);
    };

    //  Ajouter ou modifier un lien
    const handleSubmit = async (values) => {
        console.log(" Données soumises :", values); // Debugging

        let { id, ...updateValues } = values; // Exclure `id` pour l'update

        if (isEditing) {
            const { error } = await supabase
                .from('links_maillage')
                .update(updateValues) // Ne pas modifier `id`
                .eq('id', id);

            if (error) {
                console.error(' Erreur lors de la mise à jour:', error.message);
            } else {
                console.log(" Mise à jour réussie !");
                setIsEditing(false);
                setSelectedItem(null);
                fetchData(); // Recharger les données après modification
            }
        } else {
            const { error } = await supabase
                .from('links_maillage')
                .insert([values]);

            if (error) {
                console.error(' Erreur lors de l’ajout:', error.message);
            } else {
                console.log(" Ajout réussi !");
                fetchData(); // Recharger les données après ajout
            }
        }
    };

    //  Annuler l'édition
    const handleCancel = () => {
        setIsEditing(false);
        setSelectedItem(null);
    };

    if (loading) {
        return <p>Chargement en cours...</p>;
    }

    if (error) {
        return <p> Erreur : {error}</p>;
    }

    return (
        <div className="contain">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
                <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 sm:mb-0">
                    Gestion des liens pour le maillage
                </h2>
                <div className="flex gap-4">
                    <Link
                        to={'add'}
                        className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-black transition w-full sm:w-auto text-center"
                    >
                        <AddIcon />
                    </Link>
                    <Link
                        to={'/upload-data'}
                        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition w-full sm:w-auto text-center"
                    >
                        Importer CSV <FileUploadIcon />
                    </Link>
                </div>
            </div>
            <div className="overflow-x-auto">
                {isEditing ? (
                    <Form
                        initialValues={selectedItem || {}}
                        fields={getFormFields(categoryParents, rulesManagement, rootContext)}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        validationSchema={maillageValidationSchema}
                    />
                ) : (
                    <Table
                        columns={[
                            'id', 'id_link', 'url', 'anchor_text',
                            'parent_link_id', 'level_number', 'score', 'root_context', 'categ_name', 'createat'
                        ]}
                        data={data}
                        onEdit={handleEdit}
                        onDelete={handleDeleteClick}
                    />
                )}
            </div>
            <ConfirmationModal
                open={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDelete}
                message="Êtes-vous sûr de vouloir supprimer cet élément ?"
            />
        </div>
    );
};

export default MaillagePage;
