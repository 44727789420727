import * as Yup from 'yup';

//   Vérifie si une regex est valide
const isValidRegex = (pattern) => {
    try {
        new RegExp(pattern);
        return true;
    } catch (e) {
        return false;
    }
};

//   Vérifie si un XPath est valide en essayant de le parser

const isValidXPath = (pattern) => {
    try {
        const parser = new DOMParser();
        const doc = parser.parseFromString('<root/>', 'application/xml');
        const evaluator = new XPathEvaluator();
        evaluator.createExpression(pattern, null);
        console.log(doc)
        return true;
    } catch (e) {
        return false;
    }
};

export const rulesValidationSchema = Yup.object().shape({
    support_url_pattern: Yup.string()
        .test('is-valid-regex', 'Le pattern regex est invalide', (value) => isValidRegex(value))
        // .default('^pieces moto(/[^/]+(/[^/]+)*)/([^/]+)$')  
        .required('Le champ est obligatoire'),

    support_page_type_pattern: Yup.string()
        .test('is-valid-xpath', 'Le pattern XPath est invalide', (value) => isValidXPath(value))
        // .default("//body[contains(@class, 'product')]") //  Exemple de placeholder
        .required('Le champ est obligatoire'),

    max_links_number: Yup.number().integer('Doit être un nombre entier').positive('Doit être un nombre positif')//.required('Nombre de Liens est requis')
    ,
    nb_column: Yup.number().integer('Doit être un nombre entier').positive('Doit être un nombre positif')//.required('Nombre de blocs est requis')
    ,
    support_block_location: Yup.string()//.required('Location est requise')
    ,
    insertion_method: Yup.string().oneOf(['before', 'after', 'append'], 'Méthode d\'insertion invalide') //.required('Méthode d\'insertion est requise')
    ,
    support_block_name: Yup.string('Doit être une chaine de caractère').nullable(),
    ancestor_categ_id: Yup.number().integer('Doit être un entier').nullable(),
    priority_score: Yup.number().integer('Doit être un entier').positive('Doit être un nombre positif').nullable(),
    start_level: Yup.number().integer('Doit être un entier').positive('Doit être un nombre positif').nullable(),
    auth_depth: Yup.number().integer('Doit être un entier').positive('Doit être un nombre positif').nullable(),
    score_min: Yup.number().integer('Doit être un entier').positive('Doit être un nombre positif').nullable(),
    root_context: Yup.string().nullable(),




    // export const rulesValidationSchema = Yup.object().shape({
    //     support_url_pattern: Yup.string()//.required('URL est requise')
    //     ,
    //     support_page_type_pattern: Yup.string().required('Type de Page est requis'),
    //     //nature: Yup.string().required('Nature est requise'),
    //     max_links_number: Yup.number().integer('Doit être un nombre entier').positive('Doit être un nombre positif')//.required('Nombre de Liens est requis')
    //     ,
    //     nb_column: Yup.number().integer('Doit être un nombre entier').positive('Doit être un nombre positif')//.required('Nombre de blocs est requis')
    //     ,
    //     support_block_location: Yup.string()//.required('Location est requise')
    //     ,
    //     insertion_method: Yup.string().oneOf(['before', 'after', 'append'], 'Méthode d\'insertion invalide') //.required('Méthode d\'insertion est requise')
    //     ,
    //     support_block_name: Yup.string('Doit être une chaine de caractère').nullable(),
    //     ancestor_categ_id: Yup.number().integer('Doit être un entier').nullable(),
    //     priority_score: Yup.number().integer('Doit être un entier').positive('Doit être un nombre positif').nullable(),
    //     start_level: Yup.number().integer('Doit être un entier').positive('Doit être un nombre positif').nullable(),
    //     auth_depth: Yup.number().integer('Doit être un entier').positive('Doit être un nombre positif').nullable(),
    //     score_min: Yup.number().integer('Doit être un entier').positive('Doit être un nombre positif').nullable(),
    //     root_context: Yup.string().nullable(),//.required('Univers est requis'),

});
export const maillageValidationSchema = Yup.object().shape({
    id_link: Yup.number().integer().nullable(),
    categ_name: Yup.string().nullable(),
    level_number: Yup.number().nullable(),
    root_context: Yup.string().nullable(),
    url: Yup.string().url('URL invalide').nullable(),
    anchor_text: Yup.string().nullable(),
    parent_link_id: Yup.string().nullable(),
    score: Yup.number().nullable(),
});
// export const maillageValidationSchema = Yup.object().shape({
//     categ_name: Yup.string().required('Nom de catégorie est requis'),
//     level_number: Yup.number().required('Niveau de catégorie est requis').positive().integer(),
//     root_context: Yup.string().required('Univers est requis'),
//     url: Yup.string().url('URL invalide').required('URL est requis'),
//     anchor_text: Yup.string().required('anchor_text text est requis'),
//     parent_link_id: Yup.string().required('Catégorie parente est requise'),
//     score: Yup.number().required('Score est requis').positive(),
// });
