import React, { useState } from 'react';
import useFetchData from '../hooks/useFetchData';
import Table from '../components/Table';
import Form from '../components/Form';
import supabase from '../hooks/supabaseClient';
import { Link } from 'react-router-dom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddIcon from '@mui/icons-material/Add';
import ConfirmationModal from '../components/ConfirmationModal';
import useFetchSelectOptions from '../hooks/useFetchSelectOptions';
import { getFormFields } from '../components/utils/RulesFormFields';
import { rulesValidationSchema } from '../components/utils/ValidationShema';

const RulesManagement = () => {
    // Récupération des données depuis Supabase
    const { data, error, loading, fetchData } = useFetchData('rules', 'id_rule');
    const { rootContext } = useFetchSelectOptions();

    // États pour la modification et suppression
    const [isEditing, setIsEditing] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    //   Modifier une règle
    const handleEdit = (item) => {
        console.log(" Édition de l'élément :", item);
        setSelectedItem(item);
        setIsEditing(true);
    };

    //   Préparer la suppression
    const handleDeleteClick = (item) => {
        console.log("  Suppression demandée pour :", item);
        if (!item || typeof item !== "number") {
            console.error("  Erreur : ID invalide pour la suppression", item);
            return;
        }
        setItemToDelete(item);
        setModalOpen(true);
    };

    //   Annuler la suppression
    const handleCloseModal = () => {
        setModalOpen(false);
        setItemToDelete(null);
    };

    //   Supprimer une règle
    const handleConfirmDelete = async () => {
        setModalOpen(false);

        if (!itemToDelete || typeof itemToDelete !== "number") {
            console.error("  Erreur : Aucun élément valide à supprimer !");
            return;
        }

        console.log("  Suppression en cours pour l'ID :", itemToDelete);

        const { error } = await supabase
            .from('rules')
            .delete()
            .eq('id_rule', itemToDelete);

        if (error) {
            console.error(' Erreur lors de la suppression:', error.message);
        } else {
            console.log("  Suppression réussie !");
            fetchData(); // Recharger les données après suppression
        }

        setItemToDelete(null);
    };

    //   Ajouter ou modifier une règle
    const handleSubmit = async (values) => {
        console.log("  Données soumises :", values);

        let { id_rule, ...updateValues } = values; // Exclure `id_rule` pour l'update

        if (isEditing) {
            const { error } = await supabase
                .from('rules')
                .update(updateValues)
                .eq('id_rule', id_rule);

            if (error) {
                console.error('  Erreur lors de la mise à jour:', error.message);
            } else {
                console.log("  Mise à jour réussie !");
                setIsEditing(false);
                setSelectedItem(null);
                fetchData(); // Recharger les données après modification
            }
        } else {
            const { error } = await supabase
                .from('rules')
                .insert([values]);

            if (error) {
                console.error('  Erreur lors de l’ajout:', error.message);
            } else {
                console.log("  Ajout réussi !");
                fetchData(); // Recharger les données après ajout
            }
        }
    };

    //   Annuler l'édition
    const handleCancel = () => {
        setIsEditing(false);
        setSelectedItem(null);
    };

    if (loading) {
        return <p>Chargement en cours...</p>;
    }

    if (error) {
        return <p>  Erreur : {error}</p>;
    }

    return (
        <div className="contain">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
                <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4 sm:mb-0">
                    Règles de gestion
                </h2>
                <div className="flex gap-4">
                    <Link
                        to={'add'}
                        className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition w-full sm:w-auto text-center"
                    >
                        <AddIcon />
                    </Link>
                    <Link
                        to={'/upload-data'}
                        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition w-full sm:w-auto text-center"
                    >
                        Importer CSV <FileUploadIcon />
                    </Link>
                </div>
            </div>
            <div className="overflow-x-auto">
                {isEditing ? (
                    <Form
                        initialValues={selectedItem || {}}
                        fields={getFormFields(rootContext)}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        validationSchema={rulesValidationSchema}
                    />
                ) : (
                    <Table
                        columns={[
                            'id_rule', 'support_url_pattern', 'support_page_type_pattern', 'max_links_number',
                            'nb_column', 'support_block_location', 'priority_score', 'start_level',
                            'auth_depth', 'score_min', 'createat'
                        ]}
                        data={data}
                        onEdit={handleEdit}
                        onDelete={handleDeleteClick}
                    />
                )}
            </div>
            <ConfirmationModal
                open={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDelete}
                message="Êtes-vous sûr de vouloir supprimer cet élément ?"
            />
        </div>
    );
};

export default RulesManagement;
