import React, { useState } from 'react';
import Form from '../components/Form';
import supabase from '../hooks/supabaseClient';
import { useNavigate } from 'react-router-dom';
import useFetchSelectOptions from '../hooks/useFetchSelectOptions';
import { getFormFields } from '../components/utils/MaillageFormFields';
import { maillageValidationSchema } from '../components/utils/ValidationShema';
const AddMaillage = () => {
    const [statusMessage, setStatusMessage] = useState('');
    const [statusType, setStatusType] = useState(''); // 'success' or 'error'
    const navigate = useNavigate();
    const { categoryParents, rulesManagement, rootContext } = useFetchSelectOptions();

    const handleSubmit = async (values) => {
        const { error } = await supabase.from('links_maillage').insert([values]);

        if (error) {
            console.error('Error adding maillage:', error.message);
            setStatusMessage('Erreur lors de l’ajout du maillage.');
            setStatusType('error');
        } else {
            console.log('Maillage added successfully');
            setStatusMessage('Maillage ajouté avec succès.');
            navigate('/maillage');
        }
    };


    return (
        <>
            <h2 className="text-2xl font-bold mb-6">Ajout des liens pour le maillage interne</h2>
            {statusMessage && (
                <div
                    style={{
                        padding: '10px',
                        marginBottom: '20px',
                        borderRadius: '5px',
                        color: statusType === 'success' ? 'green' : 'red',
                        border: `1px solid ${statusType === 'success' ? 'green' : 'red'}`,
                        textAlign: 'center'
                    }}
                >
                    {statusMessage}
                </div>
            )}
            <Form
                initialValues={{
                    id_link:'',
                    level_number: '',
                    url: '',
                    anchor_text: '',
                    parent_link_id: '',
                    score: '',
                    root_context: '',
                    categ_name: ''
                }}
                fields={getFormFields(categoryParents, rulesManagement, rootContext)} // Appel de la fonction
                onSubmit={handleSubmit}
                onCancel={() => navigate('/maillage')}
                validationSchema={maillageValidationSchema}
            />
        </>
    );
};

export default AddMaillage;
