
export const getFormFields = (categoryParents, rulesManagement, rootContext) => [
    { name: 'id_link', label: 'id du lien', description: 'Désigne l\'id du lien' },
    { name: 'categ_name', label: 'Nom de catégorie', description: 'Désigne le nom de la catégorie qui permet son identification. eg: la chaine de caractère du breadcrum' },
    { name: 'level_number', label: 'Rang de catégory', type: 'number', description: 'Désigne le rang de gatégorie de la page active.' },
    { name: 'root_context', label: 'Univers', description: 'Désigne l\'univers contextuel de la page active.' },
    { name: 'url', label: 'URL', description: 'Désigne l\'url du lien pour le maillage' },
    { name: 'anchor_text', label: 'anchor_text text', description: 'L\'anchor_text du lien pour le maillage.' },
    { name: 'parent_link_id', label: 'Identifiant Parent', type: 'number', description: 'Le parent de la page active' },
    { name: 'score', label: 'Score', type: 'number', description: 'Désigne le poids du lien de cette page pour le maillage interne' },
];

