import React, { useState } from 'react';
import Papa from 'papaparse';
import supabase from '../hooks/supabaseClient';
import { Button } from '@mui/material';


const CSVUploader = ({ tableName }) => {
  const [csvData, setCsvData] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [error, setError] = useState(null);
  const [rowsUploaded, setRowsUploaded] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadTime, setUploadTime] = useState(null); // État pour stocker le temps d'exécution

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          setCsvData(results.data);
          setPreviewData(results.data.slice(0, 15)); // Afficher les 15 premières lignes en aperçu
        },
        error: (error) => {
          setError(error.message);
        }
      });
    }
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleUpload = async () => {
    const batchSize = 5000; // Taille des lots pour éviter les erreurs de surcharge
    let error = null;
    let totalRowsUploaded = 0;

    setLoading(true);
    setRowsUploaded(0);
    setUploadTime(null);

    const startTime = performance.now();

    for (let i = 0; i < csvData.length; i += batchSize) {
        // Nettoyage des données avant insertion
        const batch = csvData.slice(i, i + batchSize).map(row => ({
            ...row,
           // score: row.score === "" ? null : parseInt(row.score, 10),  // Convertir score vide en null
            max_links_number: row.max_links_number === "" ? null : parseInt(row.max_links_number, 10),
            nb_column: row.nb_column === "" ? null : parseInt(row.nb_column, 10),
            priority_score: row.priority_score === "" ? null : parseInt(row.priority_score, 10),
            start_level: row.start_level === "" ? null : parseInt(row.start_level, 10),
            auth_depth: row.auth_depth === "" ? null : parseInt(row.auth_depth, 10),
            score_min: row.score_min === "" ? null : parseInt(row.score_min, 10),
           // createAt: row.createAt || new Date().toISOString() // Gérer la date si vide
        }));

        const { error: batchError } = await supabase.from(tableName).insert(batch);

        if (batchError) {
            error = batchError.message;
            break;
        } else {
            totalRowsUploaded += batch.length;
            setRowsUploaded(totalRowsUploaded);
            await delay(500);
        }
    }

    const endTime = performance.now();
    setUploadTime(((endTime - startTime) / 1000).toFixed(2));

    if (error) {
        setError(error);
    } else {
        alert(`Chargement terminé. Total des lignes chargées : ${totalRowsUploaded}`);
    }

    setLoading(false);
    setCsvData([]);
    setPreviewData([]);
};

  const handleCancel = () => {
    setCsvData([]);
    setPreviewData([]);
    setRowsUploaded(0);
    setError(null);
    setUploadTime(null);
  };

  return (
    <div className="csv-uploader-container">
      <input type="file" accept=".csv" onChange={handleFileChange} />
      {error && <p style={{ color: 'red' }}>Erreur : {error}</p>}
      {csvData.length > 0 && (
        <>
          <Button onClick={handleUpload} variant="contained" color="success" disabled={loading}>
            {loading ? 'Chargement...' : 'Importer CSV'}
          </Button>
          <Button onClick={handleCancel} variant="outlined" sx={{
            backgroundColor: 'black',
            borderColor: 'black',
            color: 'black',
            '&:hover': {
              borderColor: '#1a1a1a',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}>
            Annuler
          </Button>

          {/* Afficher le nombre de lignes chargées */}
          {rowsUploaded >= 0 && <p>Lignes chargées : {rowsUploaded}</p>}

          {/* Afficher le temps d'exécution si disponible */}
          {uploadTime && <p>Temps d'exécution : {uploadTime} secondes</p>}

          {/* Aperçu des premières lignes du fichier */}
          {previewData.length > 0 && (
            <table>
              <thead>
                <tr>
                  {Object.keys(previewData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {previewData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, idx) => (
                      <td key={idx}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

export default CSVUploader;
