import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LinkOffOutlined from "@mui/icons-material/LinkOffOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LinkIcon from '@mui/icons-material/Link'; // Option alternative si vous voulez quelque chose de légèrement différent.
import AssessmentIcon from '@mui/icons-material/Assessment';
import useAuth from '../hooks/useAuth';

const Side = () => {
  const { collapseSidebar } = useProSidebar();
  const [userName, setUserName] = useState('');
  const { user } = useAuth(); // Récupération de l'utilisateur depuis le hook useAuth
  // console.log(user)
  useEffect(() => {
    if (user && user.email) {
      // Extraction du nom d'utilisateur avant le '@'
      const extractedName = user.email.split('@')[0];
      setUserName(extractedName);
    }
    console.log("======================================>2")

  }, [user]);
  return (
    <Sidebar style={{ height: "100vh" }}>
      <Menu>
        <MenuItem
          icon={<MenuOutlinedIcon />}
          onClick={() => {
            collapseSidebar();
          }}
          style={{ textAlign: "center" }}
        >
          {" "}
          <h2 className="capitalize">{userName || 'Admin'}</h2>
        </MenuItem>

        <Link to={'/'}> <MenuItem icon={<HomeOutlinedIcon />}>Home</MenuItem></Link>
        <Link to={'/maillage'}> <MenuItem icon={<LinkIcon />}>Maillage</MenuItem></Link>
        <Link to={'/rules'}> <MenuItem icon={<AssessmentIcon />}>Règles de gestion</MenuItem></Link>
        <Link to={'/test-matching'}> <MenuItem icon={<LinkOffOutlined />}>Link Matching</MenuItem></Link>
        <Link to={'/administration-info'}> <MenuItem icon={<ReceiptOutlinedIcon />}>Documentation</MenuItem></Link>
        <Link to={'/administration-dev'}> <MenuItem icon={<HelpOutlineOutlinedIcon />}>Documentation Dev</MenuItem></Link>
      </Menu>
    </Sidebar>
  );
};

export default Side