import React from 'react';

const DocAdmin = () => {
    return (
        <>
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Documentation Admin</h2>

            <section className="mb-8">
                <h3 className="text-2xl font-semibold text-[orangered]  mb-4">Gestion des Liens de Maillage Interne</h3>

                <article className="mb-6">
                    <h4 className="text-xl font-medium text-gray-700 mb-2">Ajout</h4>
                    <p className="mb-2">Pour ajouter un lien, cliquez sur <strong className="text-[orangered] ">+</strong> dans l'onglet "Maillage", puis remplissez les champs requis.</p>
                    <p className="mb-4">Pour un ajout par importation, cliquez sur <strong className="text-[orangered] ">Importer</strong> et sélectionnez un fichier CSV.</p>

                    <h5 className="text-lg font-semibold text-gray-600 mb-2">Conditions pour un Import Réussi :</h5>
                    <ul className="list-disc pl-5 mb-4">
                        <li>Le fichier doit être encodé en UTF-8 pour éviter la déformation des anchor_text-text.</li>
                        <li>Assurez-vous que les noms de champs correspondent exactement à ceux attendus.</li>
                        <li>
                            Le champ <code className="bg-gray-100 p-1 rounded">level_number</code> doit contenir un tableau : vide <code className="bg-gray-100 p-1 rounded">{`{}`}</code> ou rempli <code className="bg-gray-100 p-1 rounded">{`{"1", "2", "2"}`}</code>.
                        </li>
                    </ul>
                </article>

                <article className="mb-6">
                    <h4 className="text-xl font-medium text-gray-700 mb-2">Modification</h4>
                    <p className="mb-4">Pour modifier un lien, cliquez sur l'icône d'édition dans l'onglet "Maillage", puis ajustez les champs nécessaires.</p>
                </article>

                <article className="mb-6">
                    <h4 className="text-xl font-medium text-gray-700 mb-2">Suppression</h4>
                    <p className="mb-2">Pour supprimer un lien, cliquez sur l'icône de suppression dans l'onglet "Maillage".</p>
                    <p className="mb-4">Pour une suppression multiple, sélectionnez les liens à supprimer, puis cliquez sur l'icône de suppression qui apparaît.</p>
                </article>
            </section>

            <section>
                <h3 className="text-2xl font-semibold text-[orangered]  mb-4">Gestion des Règles de Gestion</h3>

                <article className="mb-6">
                    <h4 className="text-xl font-medium text-gray-700 mb-2">Ajout</h4>
                    <p className="mb-2">Pour ajouter une règle de gestion, cliquez sur <strong className="text-[orangered] ">+</strong> dans l'onglet "Règles de gestion", puis remplissez les champs requis.</p>
                    <p className="mb-4">Pour un ajout par importation, cliquez sur <strong className="text-[orangered] ">Importer</strong> et sélectionnez un fichier CSV.</p>

                    <h5 className="text-lg font-semibold text-gray-600 mb-2">Conditions pour un Import Réussi :</h5>
                    <ul className="list-disc pl-5 mb-4">
                        <li>Le fichier doit être encodé en UTF-8 pour éviter la déformation des anchor_text-text.</li>
                        <li>Assurez-vous que les noms de champs correspondent exactement à ceux attendus.</li>
                        <li>
                            Le champ <code className="bg-gray-100 p-1 rounded">location</code> doit être correctement saisi, car il s'agit d'une expression HTMLRewriter.
                        </li>
                    </ul>
                </article>

                <article className="mb-6">
                    <h4 className="text-xl font-medium text-gray-700 mb-2">Modification</h4>
                    <p className="mb-4">Pour modifier une règle de gestion, cliquez sur l'icône d'édition dans l'onglet "Règles de gestion", puis ajustez les champs nécessaires.</p>
                </article>

                <article className="mb-6">
                    <h4 className="text-xl font-medium text-gray-700 mb-2">Suppression</h4>
                    <p className="mb-2">Pour supprimer une règle de gestion, cliquez sur l'icône de suppression dans l'onglet "Règles de gestion".</p>
                    <p className="mb-4">Pour une suppression multiple, sélectionnez les règles à supprimer, puis cliquez sur l'icône de suppression qui apparaît.</p>
                </article>
            </section>
        </>
    );
}

export default DocAdmin;
