// src/components/utils/getFormFields.js
export const getFormFields = (rootContext) => [
    {
        name: 'support_url_pattern',
        label: 'Regex pour l’URL',
        description: 'Exemple : ^pieces moto(/[^/]+(/[^/]+)*)/([^/]+)$',
        placeholder: '^pieces moto(/[^/]+(/[^/]+)*)/([^/]+)$', //  Ajout du placeholder
      },
      {
        name: 'support_page_type_pattern',
        label: 'XPath du type de page',
        description: "Exemple : //body[contains(@class, 'product')]",
        placeholder: "//body[contains(@class, 'product')]", //  Ajout du placeholder
      },
    { name: 'max_links_number', label: 'Nombre de Liens Maximum', description: ' désigne le nombre maximum de liens à insérer dans un bloc.' },
    { name: 'nb_column', label: 'Nombre de colonnes', description: 'désigne le nombre de colonnes dans un bloc' },
    // { name: 'links_limit', label: 'Nombre de Liens', type: 'number', description: 'Désigne le nombre de liens à afficher pour cette règle de gestion' },
    { name: 'support_block_location', label: 'Location', description: 'Définira l\'emplacement des liens dans la page et se remplit sous la forme : Un élément E dont la valeur de l\'attribut foo est exactement égale à bar E[foo= "bar"]. Pour plus consultez ce lien : ' },
    { name: 'support_block_name', label: 'Nom du bloc', description: 'Définira le nom du bloc de liens ' },
    {
        name: 'insertion_method', label: 'Méthode d\'insertion', type: 'select', options: [
            { value: 'before', label: 'Before' },
            { value: 'after', label: 'After' },
            { value: 'append', label: 'Append' },
        ], description: 'Désigne la méthode d\'insertion des liens. Eg: avant ou après la section localisée'
    },
    { name: 'root_context', label: 'L\'univers ', description:'les liens seront de cet univers. 0 correspond à tous les univers '},
    { name: 'ancestor_categ_id', label: 'Ancêtre', type: 'number' },
    { name: 'start_level', label: 'Niveau de départ', type: 'number' },
    { name: 'auth_depth', label: 'Niveau d\'arriver', type: 'number' },
    { name: 'priority_score', label: 'Priorité de la regle de gestion. Plus le nombre est grand, plus la règle est prioritaire', type: 'number' },
    { name: 'score_min', label: 'Score minimum à afficher', type: 'number' },


    // { name: 'level_number', label: 'Niveau de catégorie', type: 'array' },
];
      // { name: 'num_blocks', label: 'Nombre de blocs', type: 'number', description: 'désigne la méthode d\'insertion des liens dans ce bloc à savoir avant le bloc cible après ou après et dans le même container' },
    // //  


    //     { name: 'support_url_pattern', label: 'Url de la page support', description: 'Désigne le pattern d\'url de la page de support' },
    //     { name: 'support_page_type_pattern', label: 'support_page_type_pattern', description: 'Désigne le format des urls concernés par cette règle' },
    //     { name: 'max_links_number', label: 'Nombre de Liens', type: 'number', description: 'Désigne le nombre de liens à afficher pour cette règle de gestion' },
    //     { name: 'nb_column', label: 'Nombre de column', type: 'number', description: 'Désigne sur combien de colonnes les liens seront affichés' },
    //     { name: 'support_block_location', label: 'Location du bloc', description: 'Définira l\'emplacement des liens dans la page et se remplit sous la forme : Un élément E dont la valeur de l\'attribut foo est exactement égale à bar E[foo= "bar"]. Pour plus consultez ce lien : ' },
    //     { name: 'insertion_method', label: 'La methode', type: 'array' },
    // // 

// TODO
    // {
    //     name: 'context_univers',
    //     label: 'Univers',
    //     type: 'select',
    //     options: [
    //         { value: 'root_context', label: 'Context Courrant' },
    //         ...rootContext
    //     ],
    //     description: 'Sélectionnez le contexte univers.'
    // },