import React, { useState } from 'react';
import supabase from '../hooks/supabaseClient.js';
import { Button, TextField, Card, CardContent, Typography } from '@mui/material';

const TestMatching = () => {
    const [url, setUrl] = useState('');
    const [analysis, setAnalysis] = useState(null);

    const extractBreadcrumb = (doc) => {
        const breadcrumbElement = doc.querySelector('#breadcrumb');
        if (!breadcrumbElement) return [];

        const breadcrumbItems = Array.from(breadcrumbElement.querySelectorAll('li'));
        return breadcrumbItems.map(item => item.innerText.trim()).filter(seg => seg && !/^(Accueil|Home)$/i.test(seg));
    };

    const analyzeUrl = async () => {
        if (!url) return;

        try {
            console.log("Fetching URL:", url);
            const proxyUrl = `https://raspy-bar-6d15.acces.workers.dev/?url=${encodeURIComponent(url)}`;
            const response = await fetch(proxyUrl);

            if (!response.ok) throw new Error(`Erreur réseau: ${response.statusText}`);

            const htmlText = await response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlText, 'text/html');

            const cleanedUrl = new URL(url).pathname;
            console.log("Extracted URL Path:", cleanedUrl);

            const bodyClasses = doc.body?.classList?.value || '';
            console.log("Body classes:", bodyClasses);

            const breadcrumbSegments = extractBreadcrumb(doc);
            console.log("Extracted breadcrumb:", breadcrumbSegments);

            //  Étape 1 : Récupérer toutes les règles depuis Supabase
            const { data: rules, error } = await supabase.from('rules').select('*'); //  Récupère toutes les colonnes
            if (error) throw error;
            console.log("Fetched rules:", rules);

            let matchedRule = null;
            let detectedPageType = null;

            //  Étape 2 : Vérifier chaque règle (DYNAMIQUE)
            for (let rule of rules) {
                const pageTypePattern = rule.support_page_type_pattern;
                const regexPattern = rule.support_url_pattern;

                // Vérifier si l'un des XPaths enregistrés en base match avec `body.classList`
                const xPathClass = pageTypePattern.match(/'([^']+)'/); // Extrait la classe du XPath
                const matchesXPath = xPathClass && bodyClasses.includes(xPathClass[1]);

                if (matchesXPath) {
                    detectedPageType = pageTypePattern;

                    //  Vérification de la page d'accueil (si le breadcrumb est vide)
                    if (breadcrumbSegments.length === 0) {
                        matchedRule = rule;
                        break;
                    }

                    //  Vérification avec la regex stockée en base
                    const constructedUrl = breadcrumbSegments.map(seg =>
                        seg.toLowerCase().normalize("NFD").replace(/[̀-ͯ]/g, "").replace(/[<>*]/g, ' ').trim()
                    ).join(' / ');

                    const regex = new RegExp(`^${regexPattern}$`, 'i');
                    if (regex.test(constructedUrl) || regex.test(cleanedUrl)) {
                        matchedRule = rule;  
                        break;
                    }
                }
            }

            //  Étape 3 : Mise à jour des résultats
            setAnalysis({
                cleanedUrl,
                bodyClasses,
                detectedPageType,
                matchedRule,
                breadcrumbSegments,
                matchFound: matchedRule !== null
            });

        } catch (err) {
            console.error("Erreur d'analyse:", err);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <Typography variant="h4" className="mb-4">Test de Matching des URLs</Typography>
            <Card className="mb-4 p-4">
                <TextField
                    fullWidth
                    label="Entrer une URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={analyzeUrl} className="mt-2">Analyser</Button>
            </Card>
            {analysis && (
                <Card className="p-4">
                    <CardContent>
                        <Typography variant="h6">Résultats de l'analyse</Typography>
                        <p><strong>URL analysée :</strong> {analysis.cleanedUrl}</p>
                        <p><strong>Type de page détecté :</strong> {analysis.detectedPageType}</p>
                        <p><strong>Classes CSS :</strong> {analysis.bodyClasses}</p>
                        <p><strong>Match trouvé :</strong> {analysis.matchFound ? "  Oui" : "  Non"}</p>
                        <hr />

                        <p><strong>Breadcrumb :</strong> {analysis.breadcrumbSegments?.join(' / ') || 'Non trouvé'}</p>
                        {/* <p><strong>Univers détecté :</strong> {analysis.detectedUniverse}</p>
                        <p><strong>H1 :</strong> {analysis.h1Text}</p>
                        <p><strong>Title :</strong> {analysis.titleText}</p>
                        <p><strong>Meta Description :</strong> {analysis.metaDescription}</p>
                        <p><strong>Meta Keywords :</strong> {analysis.metaKeywords}</p> */}

                        <hr /> <br />
                        {analysis.matchFound && (
                            <>
                                <Typography variant="h6">Détails de la règle correspondante :</Typography>
                                {Object.entries(analysis.matchedRule).map(([key, value]) => (
                                    <p key={key}><strong>{key} :</strong> {value}</p>
                                ))}
                            </>
                        )}
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default TestMatching;
